import React, {useRef} from 'react';
import {Splide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


const ItemsSlider = props => {
	const splideRef = useRef();
	const options = {
		cover: true,
		rewind: true,
		pauseOnHover: true,
		autoplay: true,
		interval: 5000,
		arrows: false,
		pagination: false,
		perPage: 4,
		perMove: 1,
		fixedWidth: 277,
		fixedHeight: 382,
		gap: 28,
		padding: {
			left: 2,
			right: 2
		},
		breakpoints: {
			1279: {
				fixedWidth: 167,
				fixedHeight: 242,
				gap: 18,
			},

			767: {
				fixedWidth: 253,
				fixedHeight: 349,
				perPage: 1,
				gap: 12,
				padding: {
					left: 22,
					right: 22
				},
			}
		},
		...props.options
	};

	return (
		<>
			<div className="new-menu-items__slider-buttons slider-nav__slider-counter-wrapper">
				<button className="slider-nav__slider-button slider-nav__prev-slide new-menu-items__slider-button new-menu-items__slider-button--prev"
								onClick={() => {splideRef.current.splide.go('-1')}}/>
				<button className="slider-nav__slider-button slider-nav__next-slide new-menu-items__slider-button new-menu-items__slider-button--next"
								onClick={() => {splideRef.current.splide.go('+1');}}/>
			</div>
			<Splide className={props.className}
							ref={splideRef}
							options={options}>{ props.children }</Splide>
		</>
	);
}

export default ItemsSlider;