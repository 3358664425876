import React, { Component } from "react";
import ReactPlayer from "react-player";
import newVideo from '../assets/video/new_video.mp4'

const Video = () => {

	return (
		<>
			<ReactPlayer
				url='https://www.youtube.com/watch?v=RFpgNGiiKHU'
				width='100%'
				height='100%'
				playing={true}
				loop={true}
				muted={true}
				pip={false}
				controls={false}
				playsinline={true}
				config={{
					youtube: {
						playerVars: {
							controls: 0,
							rel: 0,
							showinfo: 0
						}
					}
				}} />
		</>
	);
};

// const Video = (props) => {
// 	const videoRef = useRef()
//
// 	useEffect(() =>
// 		videoRef.current.load(), [])
//
// 	return (
// 		<video {...props}
// 					 preload='auto'
// 					 ref={videoRef}
// 					 muted
// 					 loop
// 					 playsInline
// 					 onCanPlayThrough={event => {
// 						 const video = event.target
//
//
// 						 if (!video.style.transition.length) {
// 							 console.log(video.style.transition)
// 							 video.style.transition = 'all .7s ease-out'
//
// 							 video.style.opacity = '0'
// 							 setTimeout(() => {
// 								 video.play()
// 								 video.style.opacity = '1'
// 							 }, 800)
// 						 }
// 					 }}
// 					 onEnded={event => event.target.style.transition = '0s'}>
// 			{props.children}
// 		</video>
// 	);
// }
//
export default Video;
