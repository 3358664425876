import React, { useEffect } from "react";
import animateScrollTo from "animated-scroll-to";
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  useEffect(() => {
    AOS.init();
  });

  const socials = [
    {
      link:
        "https://www.facebook.com/%D0%A5%D0%B0%D1%87%D0%B0%D0%BF%D1%83%D1%80%D0%B8-%D0%A5%D0%B8%D0%BD%D0%BA%D0%B0%D0%BB%D1%8C%D0%B5%D0%B2%D0%B8%D1%87-315715358807979",
      icon: "/assets/img/facebook-icon.svg",
    },
    {
      link: "https://www.instagram.com/hachapuri_hinkalevich/",
      icon: "/assets/img/instagram-icon.svg",
    },
    {
      link: "https://wa.me/77759796984",
      icon: "/assets/img/whatsapp-icon.svg",
    },
  ];
  const defaultOptions = { verticalOffset: -50 };

  const handleScroll = (event) => {
    event.preventDefault();
    animateScrollTo(
      document.querySelector(event.target.dataset.to),
      defaultOptions
    );
  };

  return (
    <footer
      className="page-footer"
      data-aos="fade-in"
      data-aos-offset="100px"
      data-aos-easing="ease-in-out"
      data-aos-duration="1000"
    >
      <div className="page-footer__wrapper">
        <div className="page-footer__nav-and-info">
          <nav className="page-footer__navigation">
            <ul className="page-footer__nav-list">
              <li className="page-footer__nav-item">
                <a
                  href="#"
                  className="page-footer__nav-link"
                  data-to="about-us"
                  onClick={handleScroll}
                >
                  О нас
                </a>
              </li>
              <li className="page-footer__nav-item">
                <a
                  href="#"
                  className="page-footer__nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    animateScrollTo(document.querySelector(".menu"));
                  }}
                >
                  Меню
                </a>
              </li>
              <li className="page-footer__nav-item">
                <a
                  href="#"
                  className="page-footer__nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    animateScrollTo(document.querySelector(".delivery"));
                  }}
                >
                  Доставка
                </a>
              </li>
              <li className="page-footer__nav-item">
                <a
                  href="#"
                  className="page-footer__nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    animateScrollTo(document.querySelector(".gallery"));
                  }}
                >
                  Галерея
                </a>
              </li>
              <li className="page-footer__nav-item">
                <a
                  href="#"
                  className="page-footer__nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    animateScrollTo(document.querySelector(".contacts"));
                  }}
                >
                  Контакты
                </a>
              </li>
            </ul>
          </nav>
          <ul className="page-footer__socials-list">
            {socials.map((elem, index) => (
              <li className="page-footer__social-item" key={index}>
                <a
                  href={elem.link}
                  target="_blank"
                  className="page-footer__social-link"
                  style={{ backgroundImage: `url('${elem.icon}')` }}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="page-footer__socials-wrapper">
          <div className="page-footer__logo-wrapper">
            <picture>
              <source
                media="(min-width: 1280px)"
                srcSet={require('../assets/img/logo-desktop@2x.png')}
              />
              <source
                media="(min-width: 768px)"
                srcSet={require('../assets/img/logo-desktop@2x.png')}
              />
              <img
                className="page-footer__logo"
                src={require('../assets/img/logo-desktop@2x.png')}
                srcSet={require('../assets/img/logo-desktop@2x.png')}
                alt=""
              />
            </picture>
            <picture>
             
              <img
                className="page-footer__logo page-footer__second_logo"
                src={require('../assets/img/second_footer_logo.PNG')}
                srcSet={require('../assets/img/second_footer_logo.PNG')}
                alt=""
              />
            </picture>
            <span className="page-footer__copyright">©2020 hinkalnaya.kz</span>
          </div>
          <a
            href="https://doovertech.com/ru"
            className="page-footer__developer-copyright"
          >
            <div className="page-footer__copyright-dev-icon">
              <svg width="24" height="12" fill="none">
                <path
                  d="M13.6.236a1.161 1.161 0 00-1.429.808l-2.58 9.29a1.161 1.161 0 102.237.622l2.581-9.29a1.161 1.161 0 00-.808-1.43zM5.775.463a1.161 1.161 0 00-1.636.15L.27 5.268A1.161 1.161 0 00.27 6.755l3.87 4.635a1.159 1.159 0 001.637.147 1.161 1.161 0 00.147-1.636L2.674 6.01l3.251-3.91a1.161 1.161 0 00-.15-1.636zM23.73 5.246L19.86.61A1.161 1.161 0 1018.076 2.1l3.25 3.892-3.252 3.911a1.161 1.161 0 101.786 1.485l3.87-4.655a1.161 1.161 0 000-1.487z"
                  fill="#000"
                />
              </svg>
            </div>
            <div className="page-footer__copyright-dev-by">
              <svg
                width="19"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.472 6.128c.235.533.352 1.12.352 1.76 0 .64-.117 1.227-.352 1.76a4.122 4.122 0 01-2.256 2.272 4.186 4.186 0 01-1.584.304c-.608 0-1.136-.101-1.584-.304-.437-.213-.725-.475-.864-.784V12H.216V.352h2.032v4.272c.139-.31.421-.565.848-.768.437-.213.96-.32 1.568-.32.544 0 1.067.107 1.568.32a4.17 4.17 0 012.24 2.272zM6.6 8.768c.117-.267.176-.56.176-.88 0-.32-.059-.613-.176-.88a1.96 1.96 0 00-.496-.704 2.107 2.107 0 00-.752-.448 2.746 2.746 0 00-.96-.16c-.512 0-.965.107-1.36.32-.395.203-.656.459-.784.768v2.192c.128.31.39.57.784.784.395.203.848.304 1.36.304.352 0 .672-.053.96-.16.299-.107.55-.256.752-.448.213-.192.379-.421.496-.688zM9.506 3.76h2.192l2.16 5.12 2.16-5.12h2.256l-4.928 11.648h-2.224l1.68-3.92L9.506 3.76z"
                  fill="#000"
                />
              </svg>
            </div>
            <div className="page-footer__developer-logo-wrapper">
              <picture>
                <source
                  media="(min-width: 1280px)"
                  srcSet={require("../assets/img/doover.png")}
                />
                <img
                  className="page-footer__developer-logo"
                  src={require("../assets/img/doover.png")}
                  srcSet={require("../assets/img/doover.png")}
                  alt=""
                />
              </picture>
            </div>
          </a>
          <ul className="page-footer__partners">
            <li className="page-footer__partner">
              <a
                href="https://shipudim.kz/"
                className="page-footer__partner-link"
                target="_blank"
              >
                <picture>
                  <source
                    media="(min-width: 1280px)"
                    srcSet={require("../assets/img/shipudim__logo.png")}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={require("../assets/img/shipudim__logo.png")}
                  />
                  <img
                    className="page-footer__partner-logo"
                    src={require("../assets/img/shipudim__logo.png")}
                    srcSet={require("../assets/img/shipudim__logo.png")}
                    alt=""
                  />
                </picture>
              </a>
            </li>
            <li className="page-footer__partner">
              <a
                href="https://narsharab.kz/"
                className="page-footer__partner-link"
                target="_blank"
              >
                <picture>
                  <source
                    media="(min-width: 1280px)"
                    srcSet={require("../assets/img/narsharab__logo.png")}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={require("../assets/img/narsharab__logo.png")}
                  />
                  <img
                    className="page-footer__partner-logo"
                    src={require("../assets/img/narsharab__logo.png")}
                    srcSet={require("../assets/img/narsharab__logo.png")}
                    alt=""
                  />
                </picture>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
