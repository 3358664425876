import React, {useEffect} from 'react';
import Video from "./Video";
import animateScrollTo from 'animated-scroll-to';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Promo = () => {

	useEffect(() => {
		AOS.init()
	})

	return (
		<section className="promo"
						 data-aos="fade-in"
						 data-aos-easing="ease-in-out"
						 data-aos-duration="1000">
			<h1 className="visually-hidden">Ресторан грузинской кухни «Хачапури Хинкальевич»</h1>
			<div className="promo__video-background">
				<div className="promo__video-foreground">
					<Video className="promo__video"/>
				</div>
				{/*<Video className="promo__video">*/}
				{/*	/!*<source src={require('../assets/video/bg-video.mp4').default} type='video/mp4'/>*!/*/}
				{/*</Video>*/}
			</div>
			<div className="promo__wrapper">
				<span className="promo__restaurant-name">Хачапури Хинкальевич</span>
				<b className="promo__lead">Частичка Грузии на вашем столе</b>
				<button className="promo__scroll-button"
								type="button"
								onClick={() => {
									animateScrollTo(document.querySelector('.about-us'), {verticalOffset: -150});
								}}/>
			</div>
		</section>
	);
}

export default Promo;