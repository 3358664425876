import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import animateScrollTo from "animated-scroll-to";


const SiteNavigation = () => {
	const [isOpened, setIsOpened] = useState(false)
	const defaultOptions = { verticalOffset: -50 }

	const navigate = useNavigate();
	const location = useLocation();

	const isBirthdayPage = location.pathname.includes("birthday")

	const handleScroll = (event) => {
		setIsOpened(false);

		document.body.classList.remove('scroll-off');

		const target = event.target.dataset.to;

		if (isBirthdayPage) {
		  navigate("/");
		  setTimeout(() => {
			animateScrollTo(document.querySelector(target), defaultOptions);
		  }, 500);
		} else {
		  animateScrollTo(document.querySelector(target), defaultOptions);
		}
	  };

	return (
		<nav className="page-header__navigation site-navigation">
			<button className={`site-navigation__button${isOpened ? ' site-navigation__button--opened' : ''}`}
				type='button'
				aria-label="Навигация сайта"
				onClick={() => {
					setIsOpened(oldValue => !oldValue)
					document.body.classList.toggle('scroll-off');
				}} />
			<div className={`site-navigation__wrapper${isOpened ? ' site-navigation__wrapper--opened' : ''}`}>
						<ul className="site-navigation__list">
							<li className="site-navigation__item">
								<Link to={'/birthday'}
									className="site-navigation__link">
										Дни рождения
								</Link>
							</li>
							<li className="site-navigation__item">
								<a href="#o-nas"
									className="site-navigation__link"
									data-to=".about-us"
									onClick={handleScroll}>О нас</a>
							</li>
							<li className="site-navigation__item">
								<a href="#menu"
									className="site-navigation__link"
									data-to=".menu"
									onClick={handleScroll}>Меню</a>
							</li>
							<li className="site-navigation__item">
								<a href="#delivery"
									className="site-navigation__link"
									data-to=".delivery"
									onClick={handleScroll}>Доставка</a>
							</li>
							<li className="site-navigation__item">
								<a href="#galereya"
									className="site-navigation__link"
									data-to=".gallery"
									onClick={handleScroll}>Галерея</a>
							</li>
							<li className="site-navigation__item">
								<a href="#contacts"
									className="site-navigation__link"
									data-to=".contacts"
									onClick={handleScroll}>Контакты</a>
							</li>
						</ul>
						<a href="./assets/files/menu-hinkalnaya.pdf" target="_blank" className="site-navigation__menu">Меню</a>
			</div>
		</nav>
	)
}

export default SiteNavigation
