import React, {useEffect, useState} from 'react';
import Slider from "./Slider";
import {SplideSlide} from "@splidejs/react-splide";

import {cropImgPath} from "../polyfills";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Interier1 from "../assets/img/interier_1.jpg"
import Interier2 from "../assets/img/interier_2.jpg"
import Interier3 from "../assets/img/interier_3.jpg"
import video from "../assets/video/new_video.mp4"

const About = () => {

	useEffect(() => {
		AOS.init()
	})

	const [imgs, setImgs] = useState([
			Interier1,
			Interier2,
			Interier3,
	])

	// useEffect(() => {
	// 	fetch('https://private.narsharab.kz/catalog/images/?category=4').then(resp =>
	// 		resp.json().then(json =>{
	// 			setImgs(json.map(el =>
	// 				cropImgPath(el.image)
	// 			))}
	// 		)
	// 	)
	// }, [])

	return (
		<section className="about-us">
			<div className="about-us__wrapper"
					 data-aos="fade-up"
					 data-aos-offset="50px"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<h2 className="visually-hidden">О ресторане</h2>
				<b className="about-us__title">Когда по-настоящему вкусно...</b>
				<p className="about-us__text">Ресторан с душой на распашку!
					Описать грузинскую кухню можно всего лишь одним словом – душевная.
					И в Хачапури Хинкальевич – это чувствуется, как ни в одном другом.
					Мы готовим с огромной любовью и в каждое блюдо добавляем настоящую частичку Грузии.
				</p>
			</div>

			<div className="slider-wrapper"
					 data-aos="fade-up"
					 data-aos-offset="50px"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<Slider className="about-us__slider"
								amount={imgs.length}>{
					imgs.map((url, index) =>
						<SplideSlide className="about-us__slider-item" key={index} >
							<img className="about-us__slider-image"
								src={url}
								alt=""
							/>
						</SplideSlide>
					)
				}</Slider>
			</div>
			<div className="about-us__wrapper" data-aos="fade-up"
					 data-aos-offset="50px"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<p className="about-us__text about-us__text--2">
					Пряная с остринкой, шипящая, ароматная и сочная – вот она – Грузия на тарелке!
					У нас в гостях Вы сможете отведать как по-настоящему грузинские блюда так и потрясающие
					фантазии нашего шеф-повара. Мы очень тщательно подошли к выбору продуктов для нашего меню,
					так как хотели передать всё богатство вкусов грузинской кухни. Все специи, соуса и даже сыры
					мы привезли с разных уголков Грузии.
				</p>
			</div>
		</section>
	);
}

export default About;