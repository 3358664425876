import React, {useEffect, useRef, useState} from 'react';
import {Splide,} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


const Slider = props => {
	const [currentSlide, setCurrentSlide] = useState(1)
	const slidesAmount = props.amount
	const splideRef = useRef()
	const options = {
		cover: true,
		rewind: true,
		pauseOnHover: true,
		autoplay: true,
		interval: 5000,
		arrows: false,
		pagination: false,
		...props.options
	}

	// useEffect(() => {

	// 	const handleMoved = () => {
	// 		setCurrentSlide(splideRef.current.splide.index + 1)
	// 	}

	// 	splideRef.current.splide.on('moved', handleMoved)

	// 	return () => {
	// 		splideRef.current.splide.off('moved', handleMoved)
	// 	}
	// }, [])



// useEffect(() => {
//   if (splideRef.current) {

//     splideRef.current.splide.on('mounted', () => {

//       const handleMoved = () => {
//         setCurrentSlide(splideRef.current.splide.index + 1);
//       };

//       splideRef.current.splide.on('moved', handleMoved);

//       return () => {
//         splideRef.current.splide.off('moved', handleMoved);
//       };
//     });
//   }
// }, []);

useEffect(() => {
	const splideInstance = splideRef.current?.splide;

	if (splideInstance) {
	  const handleMoved = () => {
		setCurrentSlide(splideInstance.index + 1);
	  };

	  splideInstance.on('moved', handleMoved);

	  // Очистка обработчиков при размонтировании компонента
	  return () => {
		splideInstance.off('moved', handleMoved);
	  };
	}
  }, []); // Зависимости пусты, т.е. этот useEffect будет вызван один раз при монтировании




	return (
		<>
			<Splide className={props.className}
							ref={splideRef}
							options={options}>{ props.children }</Splide>
			<div className="slider-nav__slider-counter-wrapper">
				<button className="slider-nav__slider-button slider-nav__prev-slide"
								onClick={() => {splideRef.current.splide.go('-1')}}/>
				<div className="slider-nav__slider-counter">
					<span className="slider-nav__slider-number slider-nav__slide-current">{`${currentSlide}`.padStart(2, '0')}</span>
					<span className="slider-nav__counter-dec"> </span>
					<span className="slider-nav__slider-number slider-nav__all-slides">{`${slidesAmount}`.padStart(2, '0')}</span>
				</div>
				<button className="slider-nav__slider-button slider-nav__next-slide"
								onClick={() => {splideRef.current.splide.go('+1');}}/>
			</div>
		</>
	);
}

export default Slider