import React from 'react';
import Header from '../components/Header';
import Contacts from '../components/Contacts';
import Footer from '../components/Footer';

export const Menu = () => {
  return (
    <div>
		<Header/>
		<div class="content_container">
			<div class="content_box">
				<div class="btns_container">
					<a href="/" class="menu__button button">
						На сайт
					</a>
					<a href="/" class="menu__button button">
						Меню ру
					</a>
					<a href="/" class="menu__button button">
						Меню англ
					</a>
					<a href="/" class="menu__button button">
						Меню каз
					</a>
				</div>
			</div>
		</div>
		<Contacts/>
		<Footer/>
    </div>
  )
}
