import React, {useEffect, useState} from 'react';
import {SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import ItemsSlider from "./ItemsSlider";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Food1 from "../assets/img/food/1.jpg"
import Food2 from "../assets/img/food/2.jpg"
import Food3 from "../assets/img/food/3.jpg"
import Food4 from "../assets/img/food/4.jpg"
import Food5 from "../assets/img/food/5.jpg"

const NewMenuItems = () => {

	useEffect(() => {
		AOS.init()
	})

	const [items, setItems] = useState([
		{
			description: "Традиционная грузинская закуска из шпината, моркови, свёклы, грецких орехов, специй и граната",
			img: Food1,
			title: "Пхали ассорти",
		},
		{
			description: "Обжаренные баклажаны с домашней брынзой, базиликом и грецкими орехами",
			img: Food2,
			title: "Бадриджани с домашним сыром",
		},
		{
			description: "Мини чебуреки из сочного фарша со специями, подаются с аджикой",
			img: Food3,
			title: "Мини чебуречки",
		},
		{
			description: "Хинкали с телятиной в соусе из слив ткемали, зеленого перца, чеснока и зелени с грузинскими специями",
			img: Food4,
			title: "Хинкали с телятиной",
		},
		{
			description: "С начинкой из имеретинского сыра и запеченной глазуньей",
			img: Food5,
			title: "Хачапури по-аджарски",
		},
	])

	// useEffect(() => {
	// 	fetch('https://private.narsharab.kz/catalog/data/4/').then(resp =>
	// 		resp.json().then(json =>
	// 			setItems(json.data)
	// 		))
	// }, [])

	return (
		<section className="new-menu-items"
						 data-aos="fade-up"
						 data-aos-offset="50px"
						 data-aos-easing="ease-in-out"
						 data-aos-duration="1000">
			<div className="new-menu-items__wrapper">
				<h2 className="visually-hidden">Меню ресторана</h2>
				<b className="new-menu-items__title">Оцените хиты нашего меню</b>
				<p className="new-menu-items__description">Эти блюда поразили не одно сердце, скорее пробуйте на вкус настоящую грузинскую еду!</p>
			</div>
			<div className="new-menu-items__slider-wrapper">
				<ItemsSlider className="new-menu-items__slider">{
					items.map((el, index) =>
						<SplideSlide className="new-menu-items__slider-item" key={index}>
							<div className="new-menu-items__item-photo__wrapper">
								<picture>
									<img className="new-menu-items__item-photo"
											 src={el.img}
											 alt=""/>
								</picture>
							</div>
							<div className="new-menu-items__description-wrapper">
								<h3 className="new-menu-items__item-title">{el.title}</h3>
								<p className="new-menu-items__item-description">{el.description}</p>
							</div>
						</SplideSlide>
					)
				}</ItemsSlider>
			</div>
		</section>
	);
}

export default NewMenuItems;