import React, {useEffect, useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Form = () => {
	useEffect(() => {
		AOS.init()
	})

	const buttonStates = {
		send: 'Отправить',
		loading: 'Отправка',
		failure: 'Ошибка отправки',
		success: 'Отправлено!'
	}

	const [buttonMessage, setButtonMessage] = useState(buttonStates.send)
	const sendFeedback = event => {
		event.preventDefault();
		setButtonMessage(buttonStates.loading);
		const form = event.target;
		const submitButton = document.querySelector('.contacts__form-button');
		submitButton.disabled = true;

		fetch('https://private.narsharab.kz/feedback/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf8'
			},
			body: JSON.stringify({
				"name": form.name.value,
				"kind": 3,
				"email": form.email.value,
				"data": form.data.value
			})
		}).then(res => {
			if (res.status >= 400) {
				submitButton.classList.add('contacts__form-button--failed');
				setButtonMessage(buttonStates.failure);
			} else {
				setButtonMessage(buttonStates.success);
			}
		}).finally(() => {
			setTimeout(() => {
				setButtonMessage(buttonStates.send);
				submitButton.classList.remove('contacts__form-button--failed');
				submitButton.disabled = false;
			}, 4000);
		})
	}

	return (
		<form className="contacts__form" onSubmit={sendFeedback}
					data-aos="fade-up"
					data-aos-offset="50px"
					data-aos-delay="100"
					data-aos-easing="ease-in-out"
					data-aos-duration="1000">
			<b className="contacts__form-title">Уже были у нас? Оставьте отзыв!</b>
			<input className="contacts__form-input"
						 type="text"
						 name="name"
						 id="input-name"
						 placeholder="Имя"/>
			<input className="contacts__form-input"
						 type="email"
						 name="email"
						 id="input-email"
						 placeholder="E-mail"/>
			<textarea className="contacts__form-input contacts__form-textarea"
								name="data"
								id="input-data"
								placeholder="Отзыв"/>
			<button className="contacts__form-button" type="submit">{buttonMessage}</button>
		</form>
	)
}

export default Form;