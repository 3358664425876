import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './Pages/Home';
import { Birthday } from './Pages/Birthday';
import { Menu } from './Pages/Menu';

const App = () => {
	return (
		<>
			<Routes>
				<Route path='/' element={<Home />}/>
				<Route path='birthday' element={<Birthday />}/>
				<Route path='menu' element={<Menu />}/>
			</Routes>
		</>
	);
}

export default App
