import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Menu = () => {

	useEffect(() => {
		AOS.init()
	})

	return (
		<section className="menu">
			<div className="menu__wrapper">
				<div className="menu__description-wrapper"
						 data-aos="fade-in"
						 data-aos-offset="100px"
						 data-aos-easing="ease-in-out"
						 data-aos-duration="1000">
					<h2 className="visually-hidden">Наше меню</h2>
					<b className="menu__title">Авторские хачапури от бренд – шефа</b>
					<p className="menu__description">
						- Хачапури Сокоти – начинка из сыров - брынзы и сулугуни, с обжаренными с зеленью и чесноком шампиньонами <br/>
						- Хачапури Бостнеулис - Начинка из сыров - брынзы и сулугуни с аджапсандалом из овощей <br/>
						- Хачапури Катмис - Начинка из сыров - брынзы и сулугуни с куриным филе <br/>
						- Хачапури Хорцит - Начинка из сыров - брынзы и сулугуни с сочным мясным фаршем и яйцом <br/>
					</p>
				</div>
				<a className="menu__button" href="./assets/files/menu-hinkalnaya.pdf" target="_blank">Меню</a>
				<picture data-aos="fade-in"
								 data-aos-offset="100px"
								 data-aos-delay="100"
								 data-aos-easing="ease-in-out"
								 data-aos-duration="1000">
					<source media="(min-width: 1280px)"
									srcSet={require('../assets/img/menu_desktop.jpg')}/>
					<source media="(min-width: 768px)"
									srcSet={require('../assets/img/menu_desktop.jpg')}/>
					<img className="menu__image"
							 src={require('../assets/img/menu_desktop.jpg')}
							 srcSet={require('../assets/img/menu_desktop.jpg')}
							 alt=""/>
				</picture>
			</div>
		</section>
	)
}

export default Menu;
