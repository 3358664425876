import React, {useEffect, useState} from 'react';
import {YMaps, Map, Placemark} from "react-yandex-maps";
import Form from "./Form";
import AOS from 'aos';
import 'aos/dist/aos.css';


const Contacts = () => {

	useEffect(() => {
		AOS.init()
	})

	const branches = [
		{
			address: `г. Алматы, ул. Кабанбай батыра, д.68/51 уг. ул. Зенкова`,
			phones: [
				'+7 (727) 291 50 28',
				'+7 (775) 979 69 84',
			],
			position: {
				lat: 43.250360,
				lng: 76.957573
			}
		},
		{
			address: `г. Алматы, ул. Достык, д.29 уг. ул. Богенбай Батыра`,
			phones: [
				'+7 (727) 291 97 37',
				'+7 (701) 208 98 08',
			],
			position: {
				lat: 43.252799,
				lng: 76.955422
			}
		},
		{
			address: `г. Алматы, пр. Абая, 60 уг. ул. Манаса`,
			phones: [
				'+7 (727) 231 44 64',
				'+7 (700) 770 74 40',
			],
			position: {
				lat: 43.23951,
				lng: 76.908369
			}
		},
		{
			address: `г. Алматы, ул. Жетысу, 4, 32`,
			phones: [
				'+7 707 654 54 24',
			],
			position: {
				lat: 43.223166,
				lng: 76.83618
			}
        },
	];
	const [currentBranch, setCurrentBranch] = useState(0)
	const [doSwitch, setDoSwitch] = useState(true)
	const [switcher, setSwitcher] = useState(null)

	useEffect(() => {
		if (doSwitch) {
			const localSwitcher = setInterval(
				() => setCurrentBranch(index => (index + 1) % branches.length),
				3000)
			setSwitcher(localSwitcher)
			return () => clearInterval(localSwitcher)
		}
	}, [currentBranch])

	return (
		<section className="contacts">
			<YMaps>
				<Map className="contacts__map"
						 data-aos="fade-up"
						 data-aos-offset="50px"
						 data-aos-delay="100"
						 data-aos-easing="ease-in-out"
						 data-aos-duration="1000"
						 defaultState={{
							 center: [43.247519, 76.935721],
							 zoom: 12.5,
							 controls: ['zoomControl', 'fullscreenControl'],
						 }}
						 modules={['control.ZoomControl', 'control.FullscreenControl']}>{
					branches.map((branch, index) =>
						<Placemark key={index}
											 defaultGeometry={[branch.position.lat, branch.position.lng]}
											 onClick={() => {
												 setDoSwitch(false)
												 setCurrentBranch(index)
												 clearInterval(switcher)
											 }}
											 options={{
												 iconLayout: 'default#image',
												 iconImageHref: `/assets/img/point-map${currentBranch === index ? '-current' : ''}.png`,
												 iconImageSize: [68, 82],
												 iconImageOffset: [-34, -41]
											 }}/>
					)
				}</Map>
			</YMaps>
			<div className="contacts__wrapper">
				<div className="contacts__info-wrapper"
						 data-aos="fade-up"
						 data-aos-offset="50px"
						 data-aos-delay="100"
						 data-aos-easing="ease-in-out"
						 data-aos-duration="1000">
					<div className="contacts__description-container">
						<h2 className="visually-hidden">Контакты</h2>
						<b className="contacts__title">Как нас найти?</b>
						<p className="contacts__description">
							ул. Достык, д.29 уг. ул. Богенбай Батыра, <br/>
							ул. Кабанбай батыра, д.68/51 уг. ул. Зенкова, <br/>
							пр. Абая, 60 уг. ул. Манаса <br/>
							ул. Жетысу, 4, 32
						</p>
					</div>
					<ul className="contacts__list">
						<li className="contacts__item contacts__item--address">
							<address>{branches[currentBranch].address}</address>
						</li>
						<li className="contacts__item contacts__item--phones">{
							branches[currentBranch].phones.map((phone, index) => <div key={index}>
								<a href={`tel: ${phone}`} className="contacts__item-link">{phone}</a>
								{branches[currentBranch].phones - 1 !== index ? <br/> : null}
							</div>)
						}</li>
					</ul>
				</div>
				<h3 className="visually-hidden">Форма отправки отзыва</h3>
				<Form/>
			</div>
		</section>
	);
}

export default Contacts;
