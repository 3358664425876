import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Delivery = () => {

	useEffect(() => {
		AOS.init()
	})

	return (
		<section className="delivery">
			<div className="delivery__wrapper"
					 data-aos="fade-in"
					 data-aos-offset="50px"
					 data-aos-delay="100"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<div className="delivery__description-wrapper">
					<h2 className="visually-hidden">Доставка</h2>
					<b className="delivery__title">Под личным контролем нашего <br/> бренд-шефа</b>
					<p className="delivery__description">
						Залог успеха – качество продуктов!
						<ul>
							<li>- Мы не используем заготовки, только свежие продукты!</li>
							<li>- Все продукты проходят тщательную проверку и обработку перед приготовлением.</li>
							<li>- Мы не используем полуфабрикаты, наши пельмешки лепятся каждый день.</li>
							<li>- Только отборное мясо, соответствующее всем стандартам.</li>
							<li>- Морепродукты и рыба в наших ресторанах исключительно охлажденные.</li>
							<li>- Все наши маринады и соусы готовятся по авторским рецептам.</li>
							<li>- Свежую зелень и овощи нам привозят каждое утро.</li>
						</ul>
					</p>
				</div>
				<a className="delivery__button" href="https://wa.me/77759796984" target="_blank">Доставка</a>
				<picture data-aos="fade-in"
								 data-aos-offset="50px"
								 data-aos-easing="ease-in-out"
								 data-aos-duration="1000">
					<source media="(min-width: 1280px)"
									srcSet={`${require('../assets/img/chef.jpg')} 1x,
													${require('../assets/img/chef.jpg')} 2x`}/>
					<source media="(min-width: 768px)"
									srcSet={`${require('../assets/img/chef.jpg')} 1x,
													${require('../assets/img/chef.jpg')} 2x`}/>
					<img className="delivery__image"
							 src={`${require('../assets/img/chef.jpg')}`}
							 srcSet={`${require('../assets/img/chef.jpg')}`}
							 alt=""/>
				</picture>
			</div>
		</section>
	)
}

export default Delivery;
