import React, {useEffect, useState} from 'react';
import Slider from "./Slider";
import {SplideSlide} from "@splidejs/react-splide";
import {cropImgPath} from "../polyfills";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Guest1 from "../assets/img/guests/guest1.jpg"
import Guest2 from "../assets/img/guests/guest2.jpg"
import Guest3 from "../assets/img/guests/guest3.jpg"
import Guest4 from "../assets/img/guests/guest4.jpg"
import Guest5 from "../assets/img/guests/guest5.jpg"
import Guest6 from "../assets/img/guests/guest6.jpg"
import Guest7 from "../assets/img/guests/guest7.jpg"
import Guest8 from "../assets/img/guests/guest8.jpg"

const Gallery = () => {

	useEffect(() => {
		AOS.init()
	})

	const [imgs, setImgs] = useState([
		Guest1, Guest2, Guest3, Guest4, Guest5, Guest6, Guest7, Guest8,
	])

	// useEffect(() => {
	// 	fetch('https://private.narsharab.kz/catalog/images/?category=5').then(resp =>
	// 		resp.json().then(json =>
	// 			setImgs(json.map(el =>
	// 				cropImgPath(el.image)
	// 			))
	// 		)
	// 	)
	// }, [])

	return (
		<section className="gallery"
						 data-aos="fade-up"
						 data-aos-offset="100px"
						 data-aos-easing="ease-in-out"
						 data-aos-duration="1000">
			<div className="gallery__wrapper">
				<h2 className="visually-hidden">Галерея фотографий</h2>
				<Slider className="gallery__slider" amount={imgs.length} options={{
					perPage: 3,
					perMove: 3,
					fixedWidth: 361,
					fixedHeight: 370,
					gap: 58,
					breakpoints: {
						1279: {
							fixedWidth: 218,
							fixedHeight: 187,
							gap: 35,
						},

						767: {
							perPage: 1,
							fixedWidth: 204,
							fixedHeight: 208,
							gap: 11,
							padding: {
								left: 22,
								right: 22
							},
						}
					}
				}}>

					{
						imgs.map((url, index) =>
							<SplideSlide className="gallery__slider-item" key={index}>
									<img className="gallery__slider-image"
										src={url}
										alt=""
									/>
							</SplideSlide>
						)
					}
				</Slider>
			</div>
		</section>
	);
}

export default Gallery;